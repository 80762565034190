import * as R from 'ramda';

import helper from 'helper/helper';
import { initialDataTransform } from './transform';

const loadUsersAncilRoles = userId => {
  return helper.request.post('/loadUsersAncilRoles', { userId });
};

const loadRole = roleId => {
  return helper.request.post('/loadRole', { roleId });
};

const loadInitialData = async data => {
  let userRoles, docTypes, referralSearch, taskTypes, roleUsers, referralCombo, referrerType;

  loadUsersAncilRoles(data.currentUserId);
  await getData(data.currentUserId, 'loadDocTypes').then(data => {
    docTypes = data;
  });
  await getData(data.currentUserId, 'loadReferralSearchComboBoxes').then(
    data => {
      referralSearch = data;
    }
  );
  await getData(data.currentUserId, 'loadReferrerType').then(
    data => {
      referrerType = data;
    }
  );
  await getData(data.currentUserId, 'loadTaskTypes').then(data => {
    taskTypes = data;
  });
  await getData(data.currentUserId, 'getRoleUsers').then(data => {
    roleUsers = data;
  });
  await getData(data.currentUserId, 'loadReferralComboBoxes').then(data => {
    referralCombo = data;
  });
  // await getInitialUsersWorkList(data.currentUserId, "loadUserWorkList", '423').then(data => { userWorklist = data });

  // const roles = rolesResponse.data;
  // const defaultRole = getDefaultRole(roles)

  return { docTypes, referralSearch, taskTypes, roleUsers, referralCombo, referrerType };
};

const getData = async (zebraUserId, functionName) => {
  const response = await helper.request.post('/LoadArray', {
    zebraUserId,
    functionName,
  });
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const loadLookupReport = async (data, userId) => {
  const response = await helper.request.post('/LoadArray', {
    functionName: 'loadLookupReport',
    zebraUserId: userId,
    args: {
      ...data,
      serviceTypes: [
        ...data.serviceTypes.audiology,
        ...data.serviceTypes.dental,
        ...data.serviceTypes.diagnostics,
        ...data.serviceTypes.mse,
        ...data.serviceTypes.modification,
      ],
      transportTypes: [
        ...data.transportTypes.transport,
        ...data.transportTypes.specialTransport,
      ],
    },
  });
  const responseData = response.data ? response.data.obj : {};

  const breadCrumb = {
    functionName: 'logBreadCrumbs',
    zebraUserId: userId,
    userid: userId,
    breadcrumbkey: 'ADVSEARCHQUERY',
    refTableId: 0,
    refId: 0,
    refTable: '',
  };
  //helper.request.post('/SaveObject', breadCrumb);

  helper.request.post('/SaveObject', breadCrumb);
  return responseData;
};

const vendorLookUpStoreProcedure = async (data, setVendorSearchCall) => {
  const response = await helper.request.post('/LoadArray', {
    functionName: 'vendorLookupSP',
    ...data,
  });
  if (response.data) {
    setVendorSearchCall(true);
  }
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};


const getEvaluateVendorData = async (data, setOpenVendorEvaluateModal) => {
  const response = await helper.request.post('/LoadArray', {
    functionName: 'evaluateVendor',
    ...data,
  });
  if (response.data) {
    setOpenVendorEvaluateModal(true);
  }
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getVendorHistoryData = async (data, setOpenVendorHistoryModal) => {
  const response = await helper.request.post('/LoadArray', {
    functionName: 'vendorHistoryPricing',
    ...data,
  });
  if (response.data) {
    setOpenVendorHistoryModal(true);
  }
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const vendorLookUpAssign = async (data) => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const vendorLookupSuggestions = async data => {
  if (!data)
    return ({});
  const response = await helper.request.post('/LoadArray', {
    functionName: 'vendorLookupSuggestions',
    ...data,
  });
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const vendorLookupTop5ByLocation = async data => {
  const response = await helper.request.post('/LoadArray', {
    functionName: 'vendorLookupTop5ByLocation',
    ...data,
  });
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const vendorLookupTop5ByCount = async data => {
  const response = await helper.request.post('/LoadArray', {
    functionName: 'vendorLookupTop5MostUsed',
    ...data,
  });
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

// const getInitialUsersWorkList = async (zebraUserId, functionName, user_id) => {
//   const response = await helper.request.post('/LoadArray', { zebraUserId, functionName, user_id: user_id || '423' });
//   const responseData = response.data ? response.data.obj : {};
//   return responseData;
// };

const getUsersWorkList = async (data, currentUserId) => {
  let dontHave = false;
  if (data?.id === 0 || data?.id === -1) {
    dontHave = true;
  }
  const response = await helper.request.post('/LoadArray', {
    zebraUserId: dontHave ? currentUserId : data?.zebraId,
    functionName: 'loadUserWorkList',
    user_id: data?.id,
  });
  let zebId = '';
  if (data.zebraId == 1111) {
    zebId = -1
  }
  else if (data.zebraId == 2222) {
    zebId = 0;
  }
  else zebId = data.zebraId;
  const breadCrumb = {
    functionName: 'logBreadCrumbs',
    zebraUserId: zebId,
    // zebraUserId: (data.zebraId==1111||data.zebraId==2222)?0:data.zebraId,
    userid: data.id,
    breadcrumbkey: 'REFRESHWORKLIST',
    refTableId: 0,
    refId: 0,
    refTable: '',
  };
  //helper.request.post('/SaveObject', breadCrumb);

  helper.request.post('/SaveObject', breadCrumb);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getDefaultRole = roles => {
  const defaultRoleObject = R.find(role => role.defaultrole > 0, roles);
  if (defaultRoleObject) {
    return defaultRoleObject.defaultrole;
  }

  return 0;
};

const getDefaultCostSavingsPercent = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    serviceType: data.serviceType,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveFees = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    serviceId: data.serviceId,
    referralId: data.referralId,
    serviceFees: data.serviceFees,
    costSavings: data.costSavings,
    catId: data.catId,
    fromLang: data.fromLang,
    toLang: data.toLang,
    table: data.table
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};


const saveTransportLeg = async data => {
  let body = { ...data, functionName: "saveTransportLeg" }
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveSPTLeg = async data => {
  let body = { ...data, functionName: "saveSpecialTransportLeg" }
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const saveCosts = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    serviceId: data.serviceId,
    referralId: data.referralId,
    serviceCosts: data.serviceCosts,
    catId: data.catId,
    fromLang: data.fromLang,
    toLang: data.toLang,
    table: data.table
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getServiceIdFromReference = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    serviceId: data.serviceId,
    serviceType: data.serviceType
  };
  const response = await helper.request.post('/getServiceIdFromReference', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const loadProductSearchList = async data => {
  const response = await helper.request.post('/ProductSelect', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const productSubmit = async data => {
  const response = await helper.request.post('/getProductCatalogData', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const loadHCPC = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    lookupData: data.code,
    date: data.date,
    zipcode: data.zipcode,
    mod1: data.mod1,
    mod2: data.mod2,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const lookupHcpcWithLimit = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    lookupData: data.lookupData,
    isDescription: data.isDescription,
    start: data.start,
    limit: data.limit,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const hcpcTermCheck = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    code: data.code,
    state: data.state,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveHHCHCPC = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveMSEHCPC = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveDTLHCPC = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveMODHCPC = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getDetailLineFee = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    vendorId: data.vendorId,
    clientId: data.clientId,
    serviceTypeId: data.serviceTypeId,
    hcpc: data.hcpc,
    fs: data.fs,
    mca: data.mca,
    uc: data.uc,
    owcp: data.owcp,
    zipCodeId: data.zipCodeId,
    state: data.state,
    dos: data.dos,
    serviceId: data.serviceId,
    serviceType: data.serviceType,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const clientFeeLookup = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    clientId: data.clientId,
    serviceTypeId: data.serviceTypeId,
    hcpc: data.hcpc,
    fs: data.fs,
    uc: data.uc,
    mca: data.mca,
    owcp: data.owcp,
    zipCodeId: data.zipCodeId,
    state: data.state,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getVendorsList = async data => {
  const body = {
    functionName: "queryVendors",
    zebraUserId: data.userId,
    name: data.vendorText,
    vendorid: "%",
    start: "1",
    level: "%",
    parentid: "%"
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getReferrerInfo = async data => {
  const body = {
    functionName: 'lookupAll',
    zebraUserId: data.userId,
    firstName: data.firstName,
    lastName: data.lastName,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getClaimantList = async data => {
  const body = {
    functionName: 'claimantLookup',
    zebraUserId: data.userId,
    firstName: data.firstName,
    lastName: data.lastName,
    claimNumber: data.claimNumber,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadClaimantById = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    claims_id: data.claimsId,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadClaim = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    claims_id: data.claimsId,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getDxCodeDesc = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    code: data.code,
    desc: data.desc,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const deleteICD = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    id: data.id,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const deleteInjury = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    id: data.id,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveClaim = async data => {
  const body = {
    functionName: 'saveClaim',
    zebraUserId: data.userId,
    saveSub: 'true',
    id: data.claimId,
    claim: data.response,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getClientInfo = async data => {
  const body = {
    functionName: 'clientLookup',
    zebraUserId: data.userId,
    clientName: data.clientName,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const loadClientWithFees = async data => {
  const body = {
    functionName: 'loadClientWithFees',
    zebraUserId: data.userId,
    client_id: data.id,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getAdjusterList = async data => {
  const body = {
    functionName: 'adjusterLookup',
    zebraUserId: data.userId,
    firstName: data.firstName.trim(),
    lastName: data.lastName.trim(),
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadAdjuster = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    id: data.id,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveAdjuster = async data => {
  const body = {
    functionName: 'saveAdjuster',
    zebraUserId: data.userId,
    referralId: data.refId,
    id: data.adjusterId,
    adjuster: data.response,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getEmployerList = async data => {
  const body = {
    functionName: 'employerLookup',
    zebraUserId: data.userId,
    employerName: data.employerName,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadEmployer = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    employer_id: data.id,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveEmployer = async data => {
  const body = {
    functionName: 'saveEmployer',
    zebraUserId: data.userId,
    referralId: data.employerId,
    employer: data.response,
    claimId: data.claimId,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getPhysicianList = async data => {
  const body = {
    functionName: 'physicianLookup',
    zebraUserId: data.userId,
    firstName: data.physicianFirstName,
    lastName: data.physicianLastName,
    groupName: data.physicianGroupName,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadPhysician = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    physician_id: data.id,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const savePhysician = async data => {
  const body = {
    functionName: 'savePhysician',
    zebraUserId: data.userId,
    id: data.physicianId,
    referralId: data.refId,
    tableName: data.tableName,
    serviceId: data.serviceId,
    physician: data.response,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getAttorneyList = async data => {
  const body = {
    functionName: 'attorneyLookup',
    zebraUserId: data.userId,
    firstName: data.attorneyFirstName,
    lastName: data.attorneyLastName,
    firmName: data.attorneyFirmName,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadAttorney = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    attorney_id: data.id,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveAttorney = async data => {
  const body = {
    functionName: 'saveAttorney',
    zebraUserId: data.userId,
    id: data.attorneyId,
    claimId: data.claimId,
    attorney: data.response,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getCaseManagerList = async data => {
  const body = {
    functionName: 'caseManagerLookup',
    zebraUserId: data.userId,
    firstName: data.firstName.trim(),
    lastName: data.lastName.trim(),
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadCaseManager = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    id: data.id,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveCaseManager = async data => {
  const body = {
    functionName: 'saveCaseManager',
    zebraUserId: data.userId,
    id: data.caseManagerId,
    referralId: data.refId,
    caseManager: data.response,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadReferrer = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    id: data.id,
  };
  if (data.functionName === 'loadPhysician') {
    body['physician_id'] = body['id'];
    delete body['id'];
  }
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getZipList = async data => {
  const body = {
    functionName: 'loadZipcodeList',
    zebraUserId: data.userId,
    zipCode: data.zipCode,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLocationList = async data => {
  const body = {
    functionName: 'locationLookup',
    locationName: data.locationName,
    addr: data.addr,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const saveReferrer = async data => {
  const body = {
    functionName: 'saveReferrer',
    zebraUserId: data.userId,
    id: data.referrerId,
    referralId: data.refId,
    referrer: data.response,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveReferral = async data => {
  const body = {
    functionName: 'saveReferral',
    zebraUserId: data.currentUserId,
    id: data.referralId,
    referral: data.response,
  };
  const response = await helper.request.post('/SaveObject', body);
  if (data.referralId > 0) {
    const breadCrumb = {
      functionName: 'logBreadCrumbs',
      zebraUserId: data.currentUserId,
      userid: data.currentUserId,
      breadcrumbkey: 'SAVESERVICE',
      refTableId: 0,
      refId: data.referralId,
      refTable: '',
    };
    helper.request.post('/SaveObject', breadCrumb);
  }
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const postTransfer = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const getNotesData = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const loadEligibility = async data => {
  const response = await helper.request.post('/loadEligibility', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadNotes = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getFollowupData = async data => {
  const response = await helper.request.post('/loadFollowupInfo', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const saveNotesData = async data => {
  const response = await helper.request.post('/SavingNotes', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const saveFollowUp = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const getCancelReasons = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const postCancelService = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  const breadCrumb = {
    functionName: 'logBreadCrumbs',
    zebraUserId: data.zebraUserId,
    userid: data.zebraUserId,
    breadcrumbkey: 'CANCELSERVICE',
    refTableId: data.serviceId,
    refId: 0,
    refTable: data.tableName,
  };
  helper.request.post('/SaveObject', breadCrumb);
  return responseData;
};
const postOutcomes = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const postCancelServiceUpdateOutcomes = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  const breadCrumb = {
    functionName: 'logBreadCrumbs',
    zebraUserId: data.zebraUserId,
    userid: data.zebraUserId,
    breadcrumbkey: 'CANCELSERVICE',
    refTableId: data.serviceId,
    refId: 0,
    refTable: data.tableName,
  };
  helper.request.post('/SaveObject', breadCrumb);
  return responseData;
}

const getAdvSearchData = async data => {
  const body = {
    functionName: 'loadReferralSearchComboBoxes',
    zebraUserId: data.userId,
    userId: data.userId,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const progressTasks = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveAPApproval = async data => {
  const response = await helper.request.post('/SaveApApproval', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getAPInfo = async data => {
  const body = {
    functionName: 'loadAPInfo',
    referralId: data.referralId,
    serviceId: data.serviceId,
    referenceTable: data.tableAbbrv,
    zebraUserId: data.userId,
    userId: data.userId,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveAPinfo = async data => {
  const body = {
    functionName: 'saveAPInfo',
    accountId: data.accountId,
    invoiceNo: data.invoiceNo,
    invoiceDate: data.invoiceDate,
    invoiceAmt: data.invoiceAmt,
    serviceId: data.serviceId,
    userId: data.userId,
    zebraUserId: data.zebraUserId,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveObj = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const deleteHCPCService = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    id: data.id,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};


const getLegStatus = async data => {
  const body = {
    functionName: 'loadTransportService',
    zebraUserId: data,
  };
  const response = await helper.request.post('/loadTransportService', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getQuickSearchList = async data => {
  const body = {
    functionName: 'loadQuickSearch',
    zebraUserId: data.userId,
    serviceType: data.confServiceType,
    referralId: data.confReferralId,
    serviceId: data.confServiceId,
    vendorName: data.vendorName,
    clientName: data.clientName,
    claimantFirstName: data.claimantFirst,
    claimantLastName: data.claimantLast,
    claimNumber: data.claimNumber
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getLoadHCPCQuick = async (query, userId) => {
  const body = {
    functionName: 'loadHCPCQuick',
    lookupData: query,
    zebraUserId: userId,
    userId: userId,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getClientLookup = async (query, userId) => {
  const body = {
    functionName: 'clientLookup',
    clientName: query,
    zebraUserId: userId,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const loadAudiologyHcpcsList = async (data) => {
  const body = {
    functionName: 'loadDetailList',
    zebraUserId: data.zebraUserId,
    service_id: data.audiology_id,
    module: data.tableAbbrv,
    transport_type: data.transport_type

  };
  const response = await helper.request.post('/loadDetailList', body);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const loadCostSavings = async (data) => {
  const body = {
    functionName: 'loadCostSavings',
    zebraUserId: data.zebraUserId,
    tableAbbrv: data.tableAbbrv,
    id: data.audiology_id
  };
  const response = await helper.request.post('/loadCostSavings', body);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const getLookUpSpLocation = async (query, userId, fieldName) => {
  let body = {
    functionName: 'lookupSpLocations',
    zebraUserId: userId,
    locationName: '',
    street: '',
    city: '',
    zip: '',
    code: '',
    locationType: 'air',
    state: '',
  };
  body = { ...body, [fieldName]: query };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getEmailAddress = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const getExternalReferral = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const getAPExtract = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const postExternal = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const postExternalClient = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getGpList = async data => {
  const body = {
    functionName: 'loadGPList',
    zebraUserId: data.userId,
    gpID: data.gpId,
    gpErr: 0,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const getGpDetailList = async data => {
  const body = {
    functionName: 'loadGPDetailList',
    zebraUserId: data.userId,
    gpID: data.gpId,
    refID: data.refId,
    tblName: data.service,
  };

  const breadCrumb = {
    functionName: 'logBreadCrumbs',
    zebraUserId: data.userId,
    userid: data.userId,
    breadcrumbkey: 'OPENGPINTEGRATION',
    refTableId: 0,
    refId: 0,
    refTable: data.service,
  };
  helper.request.post('/SaveObject', breadCrumb);

  helper.request.post('/LoadArray', body);
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const logBreadCrumbs = async data => {
  helper.request.post('/SaveObject', data);
  return;
};

const refreshClientFees = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const refreshVendorFees = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};


const getGpArchiveList = async data => {
  const body = {
    functionName: 'loadGPArchive',
    zebraUserId: data.userId,
    tableAbbrv: data.service,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const getGpInfo = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
};

const getGpLastIntegration = async data => {
  const body = {
    functionName: 'loadLastIntegration',
    zebraUserId: data.userId,
  };
  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getGpData = async ({ gpId, service, userId }) => {
  const [
    listResponse,
    archiveResponse,
    lastIntegrationResponse,
  ] = await Promise.all([
    getGpList({ gpId, userId }),
    getGpArchiveList({ userId, service }),
    getGpLastIntegration({ userId }),
  ]);

  const detailResponses = [];
  R.forEach(item => {
    const refId = `${item.int_reference_table_id}`;
    detailResponses.push(getGpDetailList({ gpId, refId, service, userId }));
  }, listResponse);

  const nestedDetailList = await Promise.all(detailResponses);

  const headerList = [];
  const noDetailList = [];
  let detailList = [];

  while (listResponse.length > 0) {
    const listItem = listResponse.shift();
    const details = nestedDetailList.shift();

    if (
      R.contains(listItem.ServiceType, ['DGN', 'MOD', 'MSE', 'HHC']) ||
      listItem.Counter > 0
    ) {
      headerList.push(listItem);

      if (listItem.Counter > 0) {
        detailList = R.concat(detailList, details);
      }
    } else {
      noDetailList.push(listItem);
    }
  }

  return {
    headerList,
    noDetailList,
    detailList,
    archiveList: archiveResponse,
    lastIntegration: lastIntegrationResponse[0].runDate,
  };
};

const loadFromWorkList = async data => {
  const response = await helper.request.post('/loadReferral', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const loadUpdatedVendor = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveFileUpload = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const getDocTypes = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const getCcDocument = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const deleteDocument = async data => {
  const response = await helper.request.post('/updateDocumentFiles', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const retriveDocument = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getClaimAuth = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const saveAuthHistory = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};


const saveAuthService = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const clearAuthService = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getAuthService = async data => {
  const response = await helper.request.post('/getAuthFromService', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const loadExistingTasks = async data => {

  if (data.serviceId === 0){
    return {};
  }
  const response = await helper.request.post('/loadExistingTasks', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getCurrentTaskId = async data => {
  const response = await helper.request.post('/getCurrentTaskId', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const loadReferralAlerts = async data => {
  const response = await helper.request.post('/loadReferralAlerts', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const postrequiredFileds = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const callDynamicLoad = async data => {
  const response = await helper.request.post('/callDynamicLoad', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const groupTasksProgression = async data => {
  const response = await helper.request.post('/groupTasksProgression', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const groupTasksProgression2 = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const loadVendorLookupServices = async () => {
  return Promise.resolve([
    { abbreviation: 'ALL', displayName: 'All', parentId: 0 },
    { abbreviation: 'ACC', displayName: 'Accommodation', parentId: 1217 },
    { abbreviation: 'AUD', displayName: 'Audiology', parentId: 1073 },
    { abbreviation: 'DTL', displayName: 'Dental', parentId: 1074 },
    { abbreviation: 'DGN', displayName: 'Diagnostic', parentId: 1075 },
    { abbreviation: 'HHC', displayName: 'HHC', parentId: 1076 },
    { abbreviation: 'INT', displayName: 'Interpretation', parentId: 1077 },
    { abbreviation: 'MOD', displayName: 'Modification', parentId: 1078 },
    { abbreviation: 'MSE', displayName: 'MSE', parentId: 1079 },
    { abbreviation: 'SPT', displayName: 'Special Transport', parentId: 1080 },
    { abbreviation: 'TRN', displayName: 'Transportation', parentId: 1089 },
  ]);
};

const loadVendorLookupMiles = async () => {
  return Promise.resolve([5, 10, 25, 50, 100, 1000]);
};

const loadVendorLookupInitialData = async (userId) => {
  const [services, miles] = await Promise.all([
    loadVendorLookupServices(),
    loadVendorLookupMiles(),
  ]);

  const body = {
    functionName: 'loadVendorData',
    zebraUserId: `${userId}`,
  };
  const response = await helper.request.post('/LoadArray', body);
  var states = [];
  var serviceType = [];
  var languages = [];
  var subServiceType = [];
  if (response.data) {
    states = response.data.obj.States;
    serviceType = response.data.obj.ServiceType;
    languages = response.data.obj.Languages;
    subServiceType = response.data.obj.SubserviceType;
  }

  return { miles, services, states, serviceType, languages, subServiceType };
};

const loadVendorLookupSubServices = async ({ serviceId, userId }) => {
  if (!serviceId) {
    return { subServices: [] };
  }

  const body = {
    functionName: 'loadSubService',
    parentId: `${serviceId}`,
    zebraUserId: `${userId}`,
  };

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj.SubService : [];

  return { subServices: responseData };
};

const loadUserDashboardData = async (userId, zebraUserId) => {
  const body = { functionName: 'loadUserDashboardData', userId, zebraUserId },
    response = await helper.request.post('/LoadArray', body);
  return response.data ? response.data.obj : {};
}

const loadWorkloadData = async (userId, zebraUserId) => {
  const body = { functionName: 'loadUserWorkload', userId, zebraUserId },
    response = await helper.request.post('/LoadArray', body);
  return response.data ? response.data.obj : {};
}
const loadWIPDashboardData = async (userId, zebraUserId) => {
  const body = { functionName: 'loadWIPDashboard', userId, zebraUserId },
    response = await helper.request.post('/LoadArray', body);
  return response.data ? response.data.obj : {};
}

const loadUserWIPTaskData = async (taskId, refTable, zebraUserId) => {
  const body = { functionName: 'loadUserWIPTask', taskId, refTable, zebraUserId },
    response = await helper.request.post('/LoadArray', body);
  return response.data ? response.data.obj : {};
}
const loadBillingDashboardData = async (userId, zebraUserId) => {
  const body = { functionName: 'loadBillingHeader', userId, zebraUserId },
    response = await helper.request.post('/LoadArray', body);
  return response.data ? response.data.obj : {};
}
const loadBillingByMileStone = async (userId, zebraUserId, milestones) => {
  const body = { functionName: 'loadBillingByMileStone', userId, zebraUserId, milestones },
    response = await helper.request.post('/LoadArray', body);
  return response.data ? response.data.obj : {};
}
const loadBillingByStatus = async (userId, zebraUserId, milestones, statusName, users) => {
  const body = { functionName: 'loadBillingByStatus', userId, zebraUserId, milestones, statusName, users },
    response = await helper.request.post('/LoadArray', body);
  return response.data ? response.data.obj : {};
}
// const evaluateVendor = async ({
//   serviceAbbr,
//   serviceId,
//   userId
// }) => {
//   const body = {
//     functionName: 'evaluateVendor',
//     // lmsUserId: ,
//     serviceType: serviceAbbr,
//     serviceId,
//     // vendorId: ,
//     zebraUserId: userId
//   };

//   const response = await helper.request.post('/LoadArray', body);
//   const responseData = response.data ? response.data.obj : {};

//   return responseData
// }

const genericLoad = async data => {
  const response = await helper.request.post('/LoadArray', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getWorklistEmailReportTemplates = async data => {
  const response = await helper.request.post('/getWorklistEmailReportTemplates', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const genericSave = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const sendFinalizeAssignmentEmail = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    from: data.emailFrom,
    to: [...data.emailTo],
    subject: data.subject,
    body: data.body,
  };
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};

const getFinalizeAssignmentEmailBody = async data => {

  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    referralId: data.referralId,
    serviceIds: data.serviceIds,
    refTable: data.refTable,
    typeId: data.typeId,
    phaseId: data.phaseId
  };


  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;

}

const fetchConfirmWithContactList = async data => {

  const body = {
    functionName: data.functionName,
    zebraUserId: data.userId,
    vdrId: data.vendorId
  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const getLMSDocumentsEmail = async data => {
  const body = {
    functionName: "loadLMSDocumentsEmail",
    zebraUserId: data.userId,
    referral_id: data.referralId,
    referral_service_id: data.serviceId,
    tablename: data.refTable
  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const sendDetailEmail = async data => {
  const body = {
    functionName: "sendEmailWithAttachments",
    zebraUserId: data.zebraUserId,
    from: data.from,
    to: data.to,
    cc: data.cc,
    filePathList: data.filePathList,
    subject: data.subject,
    body: data.body
  }

  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const getReportTemplatesBody = async data => {
  const body = {
    functionName: "getEmailReportTemplates",
    zebraUserId: data.zebraUserId,
    referralId: data.referralId,
    tableType: data.tableType,
    serviceId: data.serviceId,
    subService: data.subService,
    vendorTotal: data.vendorTotal,
  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const getValidAttachment = async data => {


  // const body = {
  //   functionName: data.functionName,
  //   zebraUserId: data.zebraUserId,
  //   fileName: data.fileName,
  //   description: data.description,
  //   path: data.path
  // }

  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    referral_id: data.referral_id,
    referral_service_id: data.referral_service_id,
    tablename: data.tablename

  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const saveOverrideEligibility = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    tableType: data.tableType,
    serviceId: data.serviceId,
    note: data.note
  }

  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;

}

const checkEligibility = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    tableType: data.tableType,
    serviceId: data.serviceId,
    referralId: data.referralId
  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const saveGreatPlains = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    serviceId: data.serviceId,
    userId: data.userId,
    serverType: data.serverType
  }

  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const loadByServiceReferral = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    referralId: data.referralId
  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const loadLineItemsList = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    lmsUserId: data.lmsUserId,
    user_id: data.userId,
    referralId: data.referralId,
    serviceId: data.serviceId,
    referenceTable: data.referenceTable
  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const loadLineItemDetails = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    lmsUserId: data.lmsUserId,
    user_id: data.userId,
    referralId: data.referralId,
    serviceId: data.serviceId,
    referenceTable: data.referenceTable,
    itemId: data.itemId,
    FK_hcpc_dtl_subsrvc_id: data.FK_hcpc_dtl_subsrvc_id
  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const loadPayableLines = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    lmsUserId: data.lmsUserId,
    user_id: data.userId,
    referralId: data.referralId,
    serviceId: data.serviceId,
    referenceTable: data.referenceTable,

  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const loadInvoiceTaskData = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    taskName: data.taskName,
    userId: data.userId

  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const savePayableLines = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    apId: data.apId,
    userId: data.userId,
    dos: data.dos,
    qty: data.qty,
    modifier: data.modifier,
    itemName: data.itemName,
    vendorName: data.vendorName,
    taxId: data.taxId,
    invoiceNo: data.vendInvNo,
    invoiceDate: data.vendorInvDate,
    vendorCntrctPerUnit: data.vendorCntrctPerUnit,
    totalVendorCntrctAmt: data.totalVendorCntrctAmt,
    vendorInvRcvdDate: data.vendorInvRcvdDate,
    vendorInvApprvDate: data.vendorInvApprvDate,
    vendorBilledAmt: data.vendorBilledAmt,
    apprvdAmtPaid: data.apprvdAmtPaid,
    vendorInvDecision: data.vendorInvDecision,
    notes: data.notes,
    reconsiderationDecision: data.reconsiderationDecision,
    reconsiderationAmt: data.reconsiderationAmt,
    hcpcDtlSubsrvcId: data.hcpcDtlSubsrvcId,
    tableName: data.tableName,
    pkServiceId: data.pkServiceId,
    reasonId: data.reasonId
  }

  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const deletePayableLines = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    apId: data.apId

  }
  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const loadApprovalDenialReasons = async data => {
  const body = {
    functionName: data.functionName,

  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const loadVMProviderTypes = async () => {
  const response = await helper.request.get('/ancillary/lmsProducts');
  const responseData = response.data ? response.data : {};
  return responseData;
}

const loadVMGroups = async () => {
  const response = await helper.request.get('/ancillary/lmsNetworkGroups');
  const responseData = response.data ? response.data : {};
  return responseData;
}

const saveProvider = async data => {
  const response = await helper.request.post('/ancillary/lms-contracts', data);
  const responseData = response.data ? response.data : {};
  return responseData;
}

const searchProvider = async data => {
  const response = await helper.request.post('/ancillary/lms-contracts-search', data);
  const responseData = response.data ? response.data : {};
  return responseData;
}

const loadSkipPayableLines = async data => {
  const body = {
    functionName: data.functionName,

  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}

const updateSedgwickAttachments = async data => {
  const response = await helper.request.post('/SaveObject', data);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
};
const loadNppesPhysicianList = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    firstName: data.firstName,
    lastName: data.lastName,
    groupName: data.groupName

  }

  const response = await helper.request.post('/LoadArray', body);
  const responseData = response.data ? response.data.obj : [];
  return responseData;
}

const saveNppesPhysician = async data => {
  const body = {
    functionName: data.functionName,
    zebraUserId: data.zebraUserId,
    issued_by: data.issued_by,
    license: data.license,
    address: data.address,
    address_1: data.address_1,
    address_2: data.address_2,
    city: data.city,
    state: data.state,
    country: data.country,
    zipcode: data.zipcode,
    credential: data.credential,
    npi: data.npi,
    last_name: data.last_name,
    active: data.active,
    description: data.description,
    taxonomy: data.taxonomy,
    middle_name: data.middle_name,
    first_name: data.first_name,
    name_prefix: data.name_prefix,
    group_name: data.group_name,
    authorized_official_middle_name: data.authorized_official_middle_name,
    authorized_official_first_name: data.authorized_official_first_name,
    authorized_official_last_name: data.authorized_official_last_name
  }

  const response = await helper.request.post('/SaveObject', body);
  const responseData = response.data ? response.data.obj : {};
  return responseData;
}


export default {
  loadInitialData,
  getDefaultCostSavingsPercent,
  saveFees,
  saveCosts,
  getServiceIdFromReference,
  saveTransportLeg,
  saveSPTLeg,
  getQuickSearchList,
  loadProductSearchList,
  productSubmit,
  loadHCPC,
  lookupHcpcWithLimit,
  hcpcTermCheck,
  saveHHCHCPC,
  saveMSEHCPC,
  saveDTLHCPC,
  saveMODHCPC,
  getDetailLineFee,
  clientFeeLookup,
  getVendorsList,
  getReferrerInfo,
  getClaimantList,
  getLoadClaimantById,
  getLoadClaim,
  getDxCodeDesc,
  deleteICD,
  deleteInjury,
  deleteHCPCService,
  saveClaim,
  getAdjusterList,
  getLoadAdjuster,
  saveAdjuster,
  getEmployerList,
  getLoadEmployer,
  saveEmployer,
  getPhysicianList,
  getLoadPhysician,
  savePhysician,
  getAttorneyList,
  getLoadAttorney,
  saveAttorney,
  getCaseManagerList,
  getLoadCaseManager,
  saveCaseManager,
  getClientInfo,
  loadClientWithFees,
  getLoadReferrer,
  getZipList,
  getLocationList,
  saveReferrer,
  saveReferral,
  getUsersWorkList,
  postTransfer,
  getNotesData,
  loadEligibility,
  getLoadNotes,
  getFollowupData,
  saveNotesData,
  saveFollowUp,
  getAdvSearchData,
  getLoadHCPCQuick,
  getClientLookup,
  getLookUpSpLocation,
  getLegStatus,
  getCancelReasons,
  postCancelService,
  postOutcomes,
  postCancelServiceUpdateOutcomes,
  progressTasks,
  saveAPApproval,
  getEmailAddress,
  getExternalReferral,
  getAPExtract,
  loadLookupReport,
  postExternal,
  postExternalClient,
  getGpData,
  loadFromWorkList,
  loadUpdatedVendor,
  loadExistingTasks,
  getCurrentTaskId,
  loadReferralAlerts,
  callDynamicLoad,
  groupTasksProgression,
  groupTasksProgression2,
  loadVendorLookupInitialData,
  loadVendorLookupSubServices,
  genericLoad,
  getWorklistEmailReportTemplates,
  genericSave,
  getAPInfo,
  saveAPinfo,
  vendorLookUpStoreProcedure,
  vendorLookupSuggestions,
  vendorLookupTop5ByLocation,
  vendorLookupTop5ByCount,
  saveObj,
  postrequiredFileds,
  getDocTypes,
  getCcDocument,
  saveFileUpload,
  vendorLookUpAssign,
  deleteDocument,
  retriveDocument,
  getEvaluateVendorData,
  getVendorHistoryData,
  getClaimAuth,
  saveAuthHistory,
  loadUserDashboardData,
  loadWorkloadData,
  loadWIPDashboardData,
  loadUserWIPTaskData,
  loadBillingDashboardData,
  loadBillingByMileStone,
  loadBillingByStatus,
  saveAuthService,
  clearAuthService,
  getAuthService,
  loadAudiologyHcpcsList,
  loadCostSavings,
  sendFinalizeAssignmentEmail,
  getFinalizeAssignmentEmailBody,
  fetchConfirmWithContactList,
  getLMSDocumentsEmail,
  sendDetailEmail,
  getReportTemplatesBody,
  getValidAttachment,
  getGpInfo,
  saveOverrideEligibility,
  checkEligibility,
  logBreadCrumbs,
  refreshClientFees,
  refreshVendorFees,
  saveGreatPlains,
  loadByServiceReferral,
  loadLineItemsList,
  loadLineItemDetails,
  loadPayableLines,
  savePayableLines,
  loadInvoiceTaskData,
  deletePayableLines,
  loadApprovalDenialReasons,
  loadSkipPayableLines,
  loadNppesPhysicianList,
  saveNppesPhysician,
  loadVMProviderTypes,
  loadVMGroups,
  saveProvider,
  searchProvider,
  updateSedgwickAttachments,
};
