import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  fast360MaintenanceOperations,
  fast360MaintenanceSelectors,
  fast360MaintenanceActions,
} from '../store/index';
import {
  fast360Operations,
  fast360Selectors,
} from '../../fast-360/store/index';
import VendorEdit from './VendorEdit';
import VendorMaintenanceFileModal from './VendorMaintenanceFileModal';
import { authSelectors } from 'modules/auth/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faArrowRight, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Form,
  FormGroup,
  Row,
  Label,
  Button,
  Input
} from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';
import './MaintenanceTable.css';
import './VendorMaintenance.css';
import { find } from 'ramda';
import * as R from 'ramda';

const VENDOR_NAME_ID = 'VENDOR_NAME_ID';
const TAXID = 'TAXID';
const TELEPHONE_NUMBER = 'TELEPHONE_NUMBER';
const PRIMARY_CONTACT = 'PRIMARY_CONTACT';

const VendorMaintenance = ({
  vendorMaintenanceList,
  getVendorMaintenanceList,
  advancedQueryVendor,
  loadVendorLookupInitialData,
  pathInfo,
  getVendorPath,
  getVendorCount,
  vendorCount,
  resetVendorMaintenanceList,
  services,
  states,
  serviceType,
  languages,
  subServiceType,
  resetNewContactAction,
  resetSaveVendorAction,
  userId,
  loadVendorDocTypes,
  resetLoadingNewVendorAction,
  resetVendorPath
}) => {

  const options = [
    { value: VENDOR_NAME_ID, label: 'Vendor Name/ID' },
    { value: TAXID, label: 'Tax ID' },
    { value: TELEPHONE_NUMBER, label: 'Telephone Number' },
    { value: PRIMARY_CONTACT, label: 'Primary Contact' }
  ]
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [findVendorVal, setFindVendorVal] = useState('');
  const [tableVendorMaintenanceData, setTableVendorMaintenanceData] = useState([]);
  const [openNewVendor, setOpenNewVendor] = useState(false);
    const [openAdvanced, setOpenAdvanced] = useState(false);
  const [start, setStart] = useState(0);
  const [fileIconClicked, setFileIconClicked] = useState(false);
  const [isOpenFileScreenModal, setIsOpenFileScreenModal] = useState(false);
  const [vendorItem, setVendorItem] = useState({});
  const [vendorEditItem, setVendorEditItem] = useState({});
  const [openEditVendorScreen, setOpenEditVendorScreen] = useState(false);
  const [searchFields, setSearchFields] = useState({
      city: '',
      county: '',
      state: '',
      zipCode: '',
      radius: '0',
      firmName: '',
      dba: '',
      type: '0',
      gpCode: '',
      gpName: '',
      subService: '-1',
      language: '-1',
      level: '',
      tin: '',
      sortBy: 'Company Name',
      active: 'All',
      contracted: 'All',
      reCredentialedBefore: '',
    });
  const [enableLanguage, setEnableLanguage] = useState(false);
  const [enableSubService, setEnableSubService] = useState(false);
  const [filteredSubService, setFilteredSubService] = useState([]);
  const [path, setPath] = useState('');
  const [levelupScreen, setLevelupScreen] = useState(false);
  const [blueArrowScreen, setBlueArrowScreen] = useState(false);
  const [blueArrowItem, setBlueArrowItem] = useState({});
  const recordsPerPage = 25;

  useEffect(() => {
    if(levelupScreen == false && blueArrowScreen === false) {
    const params = {
      functionName: "queryVendors",
      vendorid: '%',
      level: "%",
      name: findVendorVal ? '%' + findVendorVal + '%' : '%%',
      parentid: "%",
      start: start.toString(),
      searchby: selectedOption.label
    }
    getVendorMaintenanceList(params);
  } else if(blueArrowScreen ||  levelupScreen) {
    onClickBlueArrowHandler(blueArrowItem)
  }

  }, [start])

  useEffect(() => {
    const docPayload = {
      functionName: 'loadVendorDocTypes',
      zebraUserId: userId,
      parent_id: 1100
    }
    loadVendorDocTypes(docPayload);
    resetVendorPath();
    setPath('');
    const countPayload = {
      functionName: "getVendorCount",
      level: '0',
      parentid: "%",
      name: '%%',
      vendorid: '%',
      searchby: null
    }

    getVendorCount(countPayload)
  }, [])

  useEffect(() => {
    loadVendorLookupInitialData(userId);
  }, []);


  useEffect(() => {
    if (vendorMaintenanceList && vendorMaintenanceList.length > 0) {
      setTableVendorMaintenanceData(vendorMaintenanceList);

    }
  }, [vendorMaintenanceList])


  useEffect(() => {
    if (pathInfo && pathInfo.length && pathInfo.length > 0)
      setPath(pathInfo[0]);
    if(tableVendorMaintenanceData && tableVendorMaintenanceData.length && tableVendorMaintenanceData.length > 0 && (levelupScreen || blueArrowScreen)) {
    const prntId = tableVendorMaintenanceData && tableVendorMaintenanceData.length && tableVendorMaintenanceData[0].parentId;
    const level = tableVendorMaintenanceData && tableVendorMaintenanceData.length && tableVendorMaintenanceData[0].level;
    const countPayload = {
      functionName: "getVendorCount",
      level: level.toString(),
      parentid: prntId.toString(),
      name: '%%',
      vendorid: '%',
      searchby: null
    }

    getVendorCount(countPayload)
  }
  }, [pathInfo])

  useEffect(() => {
    if (tableVendorMaintenanceData && tableVendorMaintenanceData.length && tableVendorMaintenanceData.length > 0) {
      if (tableVendorMaintenanceData[0].level > 0 && (levelupScreen || blueArrowScreen)) {
        const pathParam = {
          functionName: "getPath",
          parentid: tableVendorMaintenanceData[0].parentId,
        }
        getVendorPath(pathParam);
      } else if(tableVendorMaintenanceData[0].level == 0 && levelupScreen == true && path != '') {
        setPath('');
        const venId = tableVendorMaintenanceData && tableVendorMaintenanceData.length && tableVendorMaintenanceData[0].id;
        const countPayload = {
          functionName: "getVendorCount",
          level: "%",
          parentid: "%",
          name: '%%',
          vendorid: venId.toString(),
          searchby: null
        }
        getVendorCount(countPayload)
      } else if(findVendorVal && (levelupScreen == false && blueArrowScreen == false)) {
        const venId = tableVendorMaintenanceData && tableVendorMaintenanceData.length && tableVendorMaintenanceData[0].id;
        const countPayload = {
          functionName: "getVendorCount",
          level: findVendorVal ? '%' : '0',
          parentid: "%",
          name: findVendorVal ? '%' + findVendorVal + '%' : '%%',
          vendorid: findVendorVal ? '%' + findVendorVal + '%' : '%%',
          searchby: findVendorVal ? selectedOption.label : null
        }
    
        getVendorCount(countPayload)
      } else if(findVendorVal) {
        const venId = tableVendorMaintenanceData && tableVendorMaintenanceData.length && tableVendorMaintenanceData[0].id;
        const countPayload = {
          functionName: "getVendorCount",
          level: findVendorVal ? '%' : '0',
          parentid: "%",
          name: findVendorVal ? '%' + findVendorVal + '%' : '%%',
          vendorid: findVendorVal ? '%' + findVendorVal + '%' : '%%',
          searchby: findVendorVal ? selectedOption.label : null
        }
    
        getVendorCount(countPayload)
      } else {
        const countPayload = {
          functionName: "getVendorCount",
          level: '0',
          parentid: "%",
          name:  '%%',
          vendorid:'%%',
          searchby:  null
        }
        getVendorCount(countPayload)
        setBlueArrowScreen(false);
        setBlueArrowItem({});
        setLevelupScreen(false);
        resetVendorPath();
        setPath('');
      }
    
    }

  }, [tableVendorMaintenanceData, start])

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFindVendorVal(value);
  }

  const onFindHandler = () => {
    setStart(0);
    fetchVendorMaintenanceData(0, findVendorVal);
  }

  const handleExitNewVendor = () => {
    setOpenNewVendor(false);
    setFindVendorVal('');
    setStart(0);
    fetchVendorMaintenanceData(0, findVendorVal);
    resetNewContactAction();
    resetSaveVendorAction();
    resetLoadingNewVendorAction();
    setOpenEditVendorScreen(false);
    setVendorEditItem({});
    resetVendorPath();
    setPath('');
  }

  const fetchVendorMaintenanceData = (startIndex, findVendorVal) => {
    const params = {
      functionName: "queryVendors",
      vendorid: '%',
      level: "%",
      name: findVendorVal ? '%' + findVendorVal + '%' : '%%',
      parentid: "%",
      start: startIndex.toString(),
      searchby: selectedOption.label
    }
    getVendorMaintenanceList(params);
  }

  const handleNext = () => {
    setStart(start + recordsPerPage);
  }

  const handleBack = () => {
    if (start >= recordsPerPage) {
      setStart(start - recordsPerPage);
    }
  };

  const renderContactColumn = (item) => {
    let contactVal = '';
    if (item != null) {
      contactVal = item.primaryContact.people.last + ", " + item.primaryContact.people.first;
    }
    return contactVal;
  }

  const renderStreetColumn = (item) => {
    let streetVal = '';
    if ((item.address && item.address.street1 != null) && (item.address && item.address.street1 != "")) {
      streetVal = item.address.street1;
      if (item.address.street2 != null && item.address.street2 != "") {
        streetVal = ", " + item.address.street2;
      }
    }
    return streetVal;
  }

  const renderCityColumn = (item) => {
    let cityVal = '';
    if (item != null) {
      cityVal = item.address && item.address.zipCode && item.address.zipCode.city;
    }
    return cityVal;
  }

  const renderCountyColumn = (item) => {
    let countyVal = '';
    if (item != null) {
      countyVal = item.address && item.address.zipCode && item.address.zipCode.county;
    }
    return countyVal;
  }

  const renderStateColumn = (item) => {
    let stateVal = '';
    if (item != null) {
      stateVal = item.address && item.address.zipCode && item.address.zipCode.state;
    }
    return stateVal;
  }

  const renderZipColumn = (item) => {
    let zipVal = '';
    if (item != null) {
      zipVal = item.address && item.address.zipCode && item.address.zipCode.zip;
    }
    return zipVal;
  }

  const renderPhoneColumn = (item) => {
    let phoneVal = '';
    if (item.phone != null && item.phone != '' && item.phone != undefined) {
      phoneVal = `(${item.phone?.toString().substr(0, 3)})${item.phone?.toString().substr(3, 3)}-${item.phone?.toString().substr(6, 4)}`;
    } else {
      phoneVal = ''
    }
    return phoneVal;
  };

  const renderSubServiceColumn = (item) => {
    let subServiceListVal = "";
    for (const subService of item.serviceList) {
      subServiceListVal += ` ${subService.subService},`;
    }
    if (subServiceListVal !== "") {
      subServiceListVal = subServiceListVal.slice(0, -1);
    }
    return subServiceListVal;
  };

  const renderFeeCountColumn = (item) => {
    let feeCountVal = '';
    if (item.feeCount == 0) {
      feeCountVal = "";
    } else {
      feeCountVal = "$";
    }
    return feeCountVal;
  }

  const onClickBlueArrowHandler = (item) => {
    setBlueArrowItem(item);
    // setPath(item.name)
    const params = {
      functionName: "queryVendors",
      vendorid: '%',
      level: "%",
      name: '%%',
      parentid: item.id.toString(),
      start: start.toString(),
      searchby: null
    }
    getVendorMaintenanceList(params);
    setBlueArrowScreen(true);
    setLevelupScreen(false);
    // const countPayload = {
    //   functionName: "getVendorCount",
    //   level: "%",
    //   parentid: item.id.toString(),
    //   name: findVendorVal ? '%' + findVendorVal + '%' : '%%',
    //   vendorid: '%',
    //   searchby: null
    // }

    // getVendorCount(countPayload)
  }

  const renderLevelColumn = (item) => {
    let coulumnVal = Number(item.level);
    if (item.hasChildren) {
      return (
        <div>
          {coulumnVal} {'  '}
          <FontAwesomeIcon icon={faArrowRight} style={{ color: '#0073e6' }} onClick={() => onClickBlueArrowHandler(item)} />
        </div>
      );
    } else {
      const className = 'active-green-circle';
      return (
        <div>
          {coulumnVal} {'  '}
          <FontAwesomeIcon className={className} icon={faCircle} />
        </div>
      );
    }
  };

  const renderActiveColumn = (item) => {
    let activeVal = '';
    if (item != null) {
      if (item.active == 1) {
        activeVal = "Yes";
      } else {
        activeVal = "No";
      }
    } else {
      activeVal = "No";
    }
    return activeVal;
  }

  const renderContractedColumn = (item) => {
    let contractedVal = '';
    if (item != null) {
      if (item.contracted == 1) {
        contractedVal = "Yes";
      } else {
        contractedVal = "No";
      }
    } else {
      contractedVal = "No";
    }
    return contractedVal;
  }

  const renderFilesColumn = (item) => {
    if (item != null) {
      return (
        <span
          style={{
            marginLeft: '12px',
            cursor: 'pointer'
          }}
        >
          <FontAwesomeIcon
            icon={faFileUpload}
            onClick={() => handleFileIconClick(item)}
          />
        </span>
      );
    }
    return null;
  }


  const handleFileIconClick = (item) => {
    setFileIconClicked(true);
    setIsOpenFileScreenModal(true);
    setVendorItem(item);
  }

  const onRowDoubleClick = (item) => {
    setOpenNewVendor(true);
    setVendorEditItem(item);
    setOpenEditVendorScreen(true);
  }

  const getVendorMaintenanceLandingTable = () => {
    return (
      <div style={{marginTop: "20px"}} className='maintenanceTable'>
        <AppTable
          columns={[
            {
              header: 'ID',
              field: 'id',
            },
            {
              header: '',
              field: 'feeCount',
              render: item => renderFeeCountColumn(item),
            },
            {
              header: 'Vendor/Location Name',
              field: 'name',
            },
            {
              header: 'DBA',
              field: 'dbaName',
            },
            {
              header: 'Vendor Type',
              field: 'mainService',
            },
            {
              header: 'Contact',
              field: 'primaryContact',
              render: item => renderContactColumn(item),
            },
            {
              header: 'Street',
              field: 'address',
              render: item => renderStreetColumn(item),
            },
            {
              header: 'City',
              field: 'city',
              render: item => renderCityColumn(item),
            },
            {
              header: 'County',
              field: 'county',
              render: item => renderCountyColumn(item),
            },
            {
              header: 'State',
              field: 'state',
              render: item => renderStateColumn(item),
            },
            {
              header: 'Zipcode',
              field: 'zip',
              render: item => renderZipColumn(item),
            },
            {
              header: 'Phone',
              field: 'phone',
              render: item => renderPhoneColumn(item)
            },
            {
              header: 'Email',
              field: 'email1',
            },
            {
              header: 'GP Code',
              field: 'gpCode',
            },
            {
              header: 'TIN',
              field: 'taxId',
            },
            {
              header: 'Level',
              field: 'level',
              render: item => renderLevelColumn(item)
            },
            {
              header: 'Active',
              field: 'active',
              render: item => renderActiveColumn(item)
            },
            {
              header: 'Contracted',
              field: 'contracted',
              render: item => renderContractedColumn(item)
            },
            {
              header: 'Sub-Service',
              field: 'serviceList',
              render: item => renderSubServiceColumn(item)
            },
            {
              header: 'Files',
              field: '',
              render: item => renderFilesColumn(item)
            }
          ]
          }
          data={tableVendorMaintenanceData || []}
            autoPagination={false}
          //sortAble={true}
          resizable={true}
          sortImmediately={false}
          rowDoubleClickHandler={onRowDoubleClick}
          key="vendor-maintenance-landing-table"
        />
      </div>
    )
  }

  const onNewVendor = () => {
    setOpenNewVendor(true);
  }

  const onOpenAdvanced = () => {
    setOpenAdvanced(true);
  }

  const onCloseAdvanced = () => {
    setOpenAdvanced(false);
  }

  const onChangeAdvSearchHandler = (field, value, label = '') => {
    value = value === 'default' ? '' : value;
    if (field == 'type') {
      if (value > '') {
        setEnableSubService(true);
        const type = R.filter(item => item.value == label, serviceType);
        setFilteredSubService(R.filter(item=> item.service == type[0].abbreviation, subServiceType));
      } else {
        setEnableSubService(false);
      }
      if (label == 'Interpretation') {
        setEnableLanguage(true);
      } else {
        setEnableLanguage(false);
      }
    } 
    // Handle resets
    if (field == 'city' && searchFields.radius != '0') {
      setSearchFields({ ...searchFields, 'county': '', 'zipCode': '', [field]: value});
    } else if (field == 'county' && searchFields.radius != '0') {
      setSearchFields({ ...searchFields, 'city': '', 'zipCode': '', [field]: value});
    } else if (field == 'zipCode' && searchFields.radius != '0') {
      setSearchFields({ ...searchFields, 'city': '', 'county': '', 'state': '', [field]: value});
    } else if (field == 'state' && searchFields.radius != '0') {
      setSearchFields({ ...searchFields, 'zipCode': '', [field]: value});
    } else if (field == 'radius') {
      if (searchFields.city != '') {
        setSearchFields({ ...searchFields, 'county': '', 'zipCode': '', [field]: value});
      } else if (searchFields.county != '' || searchFields.state != '') {
        setSearchFields({ ...searchFields, 'zipCode': '', [field]: value});
      } else {
        setSearchFields({ ...searchFields, [field]: value});  
      }
    } else {
      setSearchFields({ ...searchFields, [field]: value});
    }    
  }

  const onResetAdvanced = () => {
    setSearchFields({
      city: '',
      county: '',
      state: '',
      zipCode: '',
      radius: '0',
      firmName: '',
      dba: '',
      type: '0',
      gpCode: '',
      gpName: '',
      subService: '-1',
      language: '-1',
      level: '',
      tin: '',
      sortBy: 'Company Name',
      active: 'All',
      contracted: 'All',
      reCredentialedBefore: '',
    })
    setEnableLanguage(false);
    setEnableSubService(false);
  }

  const onFindAdvanced = () => {
    setStart(0);
    
    var okToQuery = true;
    if (searchFields.radius != '0') {
      if ((searchFields.state == '' && searchFields.zipCode == '') || (searchFields.city == '' && searchFields.county == '' && searchFields.zipCode == '')) {
        alert('You must enter either a city & state, a county & state, or a zipcode to search by radius.');
        okToQuery = false;
      }
    } else if (searchFields.radius == '0' && searchFields.firmName == '' && searchFields.dba == '' && searchFields.type == '0' && searchFields.gpCode == '' && searchFields.gpName == '' && searchFields.level == '' && searchFields.tin == '' && searchFields.city == '' && searchFields.county == '' && searchFields.state == '' && searchFields.zipCode == '') {
      alert('You have not entered enough information to search by.  Please enter a location with radius, firm name, dba, type, GP code, GP name, level, or recredentialed date.');
      okToQuery = false;
    }
    
    if (!okToQuery) {
      return;
    }

    const payLoad = {
      functionName: 'advancedQueryVendor',
      state: searchFields.state,
      radius: searchFields.radius,
      city: searchFields.city,
      zipcode: searchFields.zipCode,
      county: searchFields.county,
      name: searchFields.firmName,
      dba: searchFields.dba,
      typeid: searchFields.type,
      gpcode: searchFields.gpCode,
      gpname: searchFields.gpName,
      subservid: searchFields.subService,
      languageid: searchFields.language,
      level: searchFields.level,
      tin: searchFields.tin,
      sort: searchFields.sortBy,
      active: searchFields.active,
      contracted: searchFields.contracted,
      recredentialed: searchFields.reCredentialedBefore,
      start: 0,
    };

    advancedQueryVendor(payLoad);
  }

  if (openNewVendor) {
    return <VendorEdit openNewVendor={openNewVendor} onExit={handleExitNewVendor} services={services} vendorEditItem={vendorEditItem} openEditVendorScreen={openEditVendorScreen} />
  }

  const levelUpHandler = () => {
    if (tableVendorMaintenanceData.length != 0) {
      const vendorId = tableVendorMaintenanceData[0].parentId;
      let level = tableVendorMaintenanceData[0].level;
      setFindVendorVal('');
      // setPath(level > 0 ? tableVendorMaintenanceData[0].name : '');
      if (level > 0 && (blueArrowScreen || levelupScreen)) {
        const params = {
          functionName: "queryVendors",
          vendorid: vendorId.toString(),
          level: "%",
          name: '%%',
          parentid: '%',
          start: '0',
          searchby: null
        }
        getVendorMaintenanceList(params);
        setLevelupScreen(true);
        setBlueArrowScreen(false);

      } else {
        const params = {
          functionName: "queryVendors",
          vendorid: '%',
          level: '0',
          name:'%%',
          parentid: "%",
          start: '0',
          searchby: selectedOption.label
        }
        getVendorMaintenanceList(params);
        setPath('');
      }
    }
  }



  return (
    <>
      {openNewVendor ? (
        <VendorEdit openNewVendor={openNewVendor} onExit={handleExitNewVendor} services={services} />
      ) : (
        <div>
          <Row className="align-items-right">
            <Col>
              <FormGroup style={{ width: "300px", marginLeft: "auto", position: 'relative' }}>
                <Select
                  value={selectedOption}
                  name="vendormaintenanceFind"
                  idKey="vendormaintenanceFind"
                  onChange={handleSelectChange}
                  options={options}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      position: 'absolute'

                    }),
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={2}>
              <FormGroup>
                <Input
                  type="text"
                  name="name"
                  id="vendorRecords"
                  placeholder="Find Vendor Records"
                  onChange={handleInputChange}
                  value={findVendorVal}
                />
              </FormGroup>
            </Col>
            <Col lg="1">
              <Button
                style={{ width: '100%' }}
                type="button"
                onClick={onFindHandler}
              >
                FIND
              </Button>
            </Col>
          </Row>
          <Row className="align-items-right">
            <Col lg="2">
              <Button
                style={{ width: '100%' }}
                type="button"
                onClick={levelUpHandler}
              >
                Level Up
              </Button>
            </Col>
            <Col lg="2" style={{ marginTop: "6px" }}>
              <span>Path:{path}</span>

            </Col>
            <Col lg="2" style={{ marginLeft: "auto" }}>
              <Button
                style={{ width: '100%' }}
                type="button"
                onClick={() => { }}
                disabled
              >
                NEW VENDOR LEVEL
              </Button>
            </Col>
            <Col lg="2">
              <Button
                style={{ width: '100%' }}
                type="button"
                onClick={onNewVendor}
              >
                NEW VENDOR
              </Button>
            </Col>
            <Col lg="2">
              <Button
                style={{ width: '100%' }}
                type="button"
              onClick={onOpenAdvanced}
              >
                ADVANCED SEARCH
              </Button>
            </Col>
          </Row>
        {openAdvanced ? (
        <div className="vendorAdvancedSearch">
          <Row>
            <Col lg="6"></Col>
            <Col lg="6">
              <Form>
                <Row>
                  <Col lg="11"></Col>
                  <Col lg="1">
                    <Button
                      id="advClose"
                      type="button"
                      onClick={onCloseAdvanced}
                      title="Close Advanced Search"
                    >CLOSE</Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advCity">
                        City
                      </Label>
                      <Input
                        id="advCity"
                        name="advCity"
                        placeholder="City"
                        onChange={(e) => onChangeAdvSearchHandler('city', e.target.value)}
                        value={searchFields.city}
                      />                      
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advCounty">
                        County
                      </Label>
                      <Input
                        id="advCounty"
                        name="advCounty"
                        placeholder="County"
                        onChange={(e) => onChangeAdvSearchHandler('county', e.target.value)}
                        value={searchFields.county}
                      />                      
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advState">
                        State
                      </Label>
                      <Input
                        id="advState"
                        name="advState"
                        placeholder="State"
                        type="select"
                        onChange={(e) => onChangeAdvSearchHandler('state', e.target.value)}
                        className="dropdown"
                        value={searchFields.state}
                      >
                        <option value='' hidden>State</option>
                        {states.map((state,index) => (
                          <option key={index} value={state.value}>
                            {state.value}
                          </option>
                        ))}
                      </Input>                      
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup floating>
                      <Label for="advZipCode">
                          Zip Code
                      </Label>
                      <Input
                        id="advZipCode"
                        name="advZipCode"
                        placeholder="Zip Code"
                        onChange={(e) => onChangeAdvSearchHandler('zipCode', e.target.value)}
                        value={searchFields.zipCode}
                      />                      
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup floating>
                      <Label for="advRadius">
                        Radius
                      </Label>
                      <Input
                        id="advRadius"
                        name="advRadius"
                        placeholder="Radius"
                        type="select"
                        onChange={(e) => onChangeAdvSearchHandler('radius', e.target.value)}
                        className="dropdown"
                        value={searchFields.radius}
                      >
                        <option value="0" hidden>--</option>
                        <option value="5">5 miles</option>
                        <option value="10">10 miles</option>
                        <option value="25">25 miles</option>
                        <option value="50">50 miles</option>
                        <option value="100">100 miles</option>
                      </Input>                      
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advFirmName">
                        Firm Name
                      </Label>
                      <Input
                        id="advFirmName"
                        name="advFirmName"
                        placeholder="Firm Name"
                        onChange={(e) => onChangeAdvSearchHandler('firmName', e.target.value)}
                        value={searchFields.firmName}
                      />                      
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advDBA">
                        DBA
                      </Label>
                      <Input
                        id="advDBA"
                        name="advDBA"
                        placeholder="DBA"
                        onChange={(e) => onChangeAdvSearchHandler('dba', e.target.value)}
                        value={searchFields.dba}
                      />                      
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advType">
                        Type
                      </Label>
                      <Input
                        id="advType"
                        name="advType"
                        placeholder="Type"
                        type="select"
                        onChange={(e) => onChangeAdvSearchHandler('type', e.target.value, e.target.options[e.target.selectedIndex].text)}
                        className="dropdown"
                        value={searchFields.type}
                      >
                        <option value='0' hidden>Type</option>
                        {serviceType.map((state,index) => (
                          <option key={index} value={state.id}>
                            {state.value}
                          </option>
                        ))}
                      </Input>                      
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advGPCode">
                        GP Code
                      </Label>
                      <Input
                        id="advGPCode"
                        name="advGPCode"
                        placeholder="GP Code"
                        onChange={(e) => onChangeAdvSearchHandler('gpCode', e.target.value)}
                        value={searchFields.gpCode}
                      />                      
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advGPName">
                        GP Name
                      </Label>
                      <Input
                        id="advGPName"
                        name="advGPName"
                        placeholder="GP Name"
                        onChange={(e) => onChangeAdvSearchHandler('gpName', e.target.value)}
                        value={searchFields.gpName}
                      />                      
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advSubService">
                        Sub-Service
                      </Label>
                      <Input
                        id="advSubService"
                        name="advSubService"
                        placeholder="Sub-Service"
                        type="select"
                        onChange={(e) => onChangeAdvSearchHandler('subService', e.target.value)}
                        className="dropdown"
                        disabled={!enableSubService}
                        value={searchFields.subService}
                      >
                        <option value='-1' hidden>Sub-Service</option>
                        {filteredSubService.map((state,index) => (
                          <option key={index} value={state.id}>
                            {state.value}
                          </option>
                        ))}
                      </Input>                      
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advLanguage">
                        Language
                      </Label>
                      <Input
                        id="advLanguage"
                        name="advLanguage"
                        placeholder="Language"
                        type="select"
                        onChange={(e) => onChangeAdvSearchHandler('language', e.target.value)}
                        className="dropdown"
                        disabled={!enableLanguage}
                        value={searchFields.language}
                      >
                        <option value='-1' hidden>Language</option>
                        {languages.map((state,index) => (
                          <option key={index} value={state.lang_id}>
                            {state.value}
                          </option>
                        ))}
                      </Input>                      
                    </FormGroup>
                  </Col>
                  <Col lg="1">
                    <FormGroup floating>
                      <Label for="advLevel">
                        Level
                      </Label>
                      <Input
                        id="advLevel"
                        name="advLevel"
                        placeholder="Level"
                        onChange={(e) => onChangeAdvSearchHandler('level', e.target.value)}
                        value={searchFields.level}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <FormGroup floating>
                      <Label for="advTIN">
                        TIN
                      </Label>
                      <Input
                        id="advTIN"
                        name="advTIN"
                        placeholder="TIN"
                        onChange={(e) => onChangeAdvSearchHandler('tin', e.target.value)}
                        value={searchFields.tin}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advSortBy">
                        Sort By
                      </Label>
                      <Input
                        id="advSortBy"
                        name="advSortBy"
                        placeholder="Sort By"
                        type="select"
                        onChange={(e) => onChangeAdvSearchHandler('sortBy', e.target.value)}
                        className="dropdown"
                        value={searchFields.sortBy}
                      >
                        <option value="Company Name">Company Name</option>                        
                        <option value="State">State</option>
                        <option value="Product">Product</option>
                        <option value="Level">Level</option>
                      </Input>                      
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advActive">
                        Active
                      </Label>
                      <Input
                        id="advActive"
                        name="advActive"
                        placeholder="Active"
                        type="select"
                        onChange={(e) => onChangeAdvSearchHandler('active', e.target.value)}
                        className="dropdown"
                        value={searchFields.active}
                      >
                        <option value="All">All</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup floating>
                      <Label for="advContracted">
                        Contracted
                      </Label>
                      <Input
                        id="advContracted"
                        name="advContracted"
                        placeholder="Contracted"
                        type="select"
                        onChange={(e) => onChangeAdvSearchHandler('contracted', e.target.value)}
                        className="dropdown"
                        value={searchFields.contracted}
                      >
                        <option value="All">All</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                  </Col>                  
                </Row>
                <Row>
                  <Col lg="9">
                  </Col>
                  <Col>
                    <Button
                      id="advReset"
                      type="button"
                      onClick={onResetAdvanced}
                      title="Reset"
                    >RESET</Button>
                  </Col>
                  <Col>
                  <Button
                      id="advFind"
                      type="button"
                      onClick={onFindAdvanced}
                      title="Find"
                    >FIND</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>          
        </div>
        ): null}
          <div>
            {getVendorMaintenanceLandingTable()}
          </div>
          <Row className="mt-3 justify-content-between" style={{ textAlign: "right" }}>
            <Col lg="1">
              <span>Result Count: {vendorCount}</span>
            </Col>
            <Col lg="auto" className="d-flex justify-content-end">
              <Button
                style={{ width: '100%',marginRight: "10px" }}
                type="button"
                onClick={handleBack}
                title="previous 25 vendors"
                disabled={start === 0}
              >
                {'<'}
              </Button>
              <Button
                style={{ width: '100%' }}
                type="button"
                onClick={handleNext}
                title="next 25 vendors"
                disabled={tableVendorMaintenanceData.length < recordsPerPage}
              >
                {'>'}
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {fileIconClicked && (
        <VendorMaintenanceFileModal
          isOpen={isOpenFileScreenModal}
          // clicked={handleFileIconClick}
          zebraId={userId}
          vendorItem={vendorItem}
          clicked={() => setIsOpenFileScreenModal(false)}
        />
      )}
    </>
  );

};


const mapStateToProps = state => {
  const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
  const user = authSelectors.getUser(state);
  const fast360VendorLookupData = fast360Selectors.getFast360VendorLookup(state)
  return {
    vendorMaintenanceList: fast360MaintenacneInfo.vendorMaintenanceList,
    userId: user.id,
    services: fast360VendorLookupData.services || [],
    vendorDocsInfo: fast360MaintenacneInfo.vendorDocsInfo,
    states: fast360VendorLookupData.states || [],
    serviceType: fast360VendorLookupData.serviceType || [],
    languages: fast360VendorLookupData.languages || [],
    subServiceType: fast360VendorLookupData.subServiceType || [],
    pathInfo: fast360MaintenacneInfo.vendorPath,
    vendorCount: fast360MaintenacneInfo.vendorCount
  };
};

const mapDispatchToProps = dispatch => ({
  getVendorMaintenanceList: data => dispatch(fast360MaintenanceOperations.getVendorMaintenanceList(data)),
  advancedQueryVendor: data => dispatch(fast360MaintenanceOperations.advancedQueryVendor(data)),
  resetVendorMaintenanceList: () =>
    dispatch(fast360MaintenanceActions.resetVendorMaintenanceList()),
  getVendorCount: data => dispatch(fast360MaintenanceOperations.getVendorCount(data)),
  resetVendorPath:  () =>
    dispatch(fast360MaintenanceActions.resetVendorPath()),
  getVendorPath: data => dispatch(fast360MaintenanceOperations.getVendorPath(data)),
  loadVendorLookupInitialData: data => dispatch(fast360Operations.loadVendorLookupInitialData(data)),
  resetNewContactAction: () =>
    dispatch(fast360MaintenanceActions.resetNewContactAction()),
  resetSaveVendorAction: () =>
    dispatch(fast360MaintenanceActions.resetSaveVendorAction()),
  loadVendorMaintenanceDocs: (data) => dispatch(fast360MaintenanceOperations.loadVendorMaintenanceDocs(data)),
  loadVendorDocTypes: (data) => dispatch(fast360MaintenanceOperations.loadVendorDocTypes(data)),
  resetLoadingNewVendorAction: () =>
    dispatch(fast360MaintenanceActions.resetLoadingNewVendorAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorMaintenance);