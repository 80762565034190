import React, { useEffect, useState } from 'react';
 
const ServicesTab = ({}) => {
    
 return (
    <div>
        Services!!!
    </div>
 )
    
    }


export default ServicesTab;