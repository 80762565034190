import React, { useState } from 'react';
import {
    Col,
    Row,
    Button,
} from 'reactstrap';
import './NewVendorEdit.css';
import ServicesTab from './ServicesTab';
import ContactsTab from './ContactsTab';
import BasicInfoTab from './BasicInfoTab';

const VendorEdit = ({openNewVendor,
    onExit,
    services,
    vendorEditItem,
    openEditVendorScreen

}) => {
    const [activeTab, setActiveTab] = useState('basicInfo');

    const renderHeaderTabContent = () => {
        switch(activeTab) {
             case 'basicInfo':
                 return  <BasicInfoTab openNewVendor={openNewVendor} onExit={onExit} services={services} vendorEditItem={vendorEditItem} openEditVendorScreen={openEditVendorScreen}/>
            case 'services':
                return <ServicesTab services={services} />;
                case 'contacts':
                    return <ContactsTab />;
        default:
            return null;
    
        }
    }

        return (
            <div>
            <Row className="align-items-right" style={{ backgroundColor: "#5f6f8f" }}>
                <Col lg="2" style={{ marginTop: "6px", color: "white" }}>
                    <span>Level:
                    </span>
                </Col>
                <Col lg="2" style={{ marginTop: "6px", color: "white" }}>
                    <span>Path:</span>

                </Col>
                <Col lg="1" style={{ marginLeft: "auto" }}>
                    <Button
                        style={{ width: '100%', color: "white", backgroundColor: "steelblue" }}
                        type="button"
                        onClick={onExit}

                    >
                        EXIT
                    </Button>
                </Col>
            </Row>
            <div className='tabs-container'>
            <Row className="tabs-header">
                <Col md={1}>
                    <span
                    className={`tab-button ${activeTab === 'basicInfo' ? 'active' : ''}`}
                    onClick={() => setActiveTab('basicInfo')} >Basic Info</span>
                   
                </Col>
                <Col md={1}>
                    <span
                    className={`tab-button ${activeTab === 'services' ? 'active' : ''}`}
                    onClick={() => setActiveTab('services')} >Services</span>
                   
                </Col>
                <Col md={1}>
                    <span
                    className={`tab-button ${activeTab === 'contacts' ? 'active' : ''}`}
                    onClick={() => setActiveTab('contacts')} >Contacts</span>
                   
                </Col>
            </Row>
            {renderHeaderTabContent()}
        </div>
        </div>
        )
}

export default VendorEdit;